import css from 'styled-jsx/css'
import { useRouter } from 'next/dist/client/router'
import { LoginStep } from '@components/login/enum'
import { FormEvent, useEffect, useRef, useState } from 'react'
import Loading from '@shared/Loading'
import { PinField } from '@shared/PinField'
import { useAuthService } from '@/services/auth/auth.service'
import { useNotiContext } from '@shared/Notification'
import HowToFindOrderModal from '@shared/Modal/HowToFindOrderModal'
import getConfig from 'next/config'

type CodeFormProps = {
  nextStep: () => void
  prevStep: string
  searchForm: { phone?: string; email?: string }
}

function onTypeCode(code: string, ref?: HTMLInputElement) {
  if (ref && code) {
    ref.value = code.toUpperCase()
  }
}

export const EnterCodeForm: React.FC<CodeFormProps> = ({ nextStep, prevStep, searchForm }) => {
  const router = useRouter()
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(120)
  const [countdownResend, setCountdownResend] = useState<number>(0)
  const [code, setCode] = useState<string>('')
  const [err, setErr] = useState<string>('')
  const pinRef = useRef<HTMLInputElement[]>(null)
  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const { notiDispatch } = useNotiContext()
  const isSentedRef = useRef<boolean>(false)

  async function resentCode() {
    setLoading(true)
    const domain = window.location.hostname
    const body: Types.LoginModel =
      prevStep === LoginStep.EMAIL
        ? {
            email: searchForm.email,
            send_sms: false,
            redirect_url: `https://${domain}/list`
          }
        : {
            phone: searchForm.phone,
            send_sms: true
          }
    const result = await login(body, false).then(
      () => true,
      () => {
        notiDispatch({
          payload: {
            title: 'Error!',
            type: 'is-danger',
            content: 'Something went wrong, please try again later'
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
        return false
      }
    )
    setLoading(false)
    if (result) {
      isSentedRef.current = true
      setCountdownResend(10)
    }
  }

  function toForgotOrder() {
    router.push({
      pathname: 'contact/forgot',
      query: { ...router.query, ref: 'login' }
    })
  }

  async function onLoginByCode(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (code.length < 6) {
      setErr('Verification code must be a 6-digit number!')
      return
    }
    setErr('')
    const body: Types.LoginModel =
      prevStep === LoginStep.EMAIL
        ? {
            email: searchForm.email,
            code
          }
        : {
            phone: searchForm.phone,
            code
          }
    setLoading(true)
    const result = await login(body, false).then(
      () => true,
      () => {
        notiDispatch({
          payload: {
            title: 'Something went wrong!',
            type: 'is-danger',
            content: 'Please make sure you typed the code correctly'
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
        return false
      }
    )
    setLoading(false)
    result && router.push('/list')
  }

  useEffect(() => {
    let timer: any
    if (countdown > 0) timer = setTimeout(() => setCountdown(countdown - 1), 1000)

    return () => clearTimeout(timer)
  }, [countdown])

  useEffect(() => {
    let timer: any
    if (countdownResend > 0) {
      timer = setTimeout(() => setCountdownResend(countdownResend - 1), 1000)
    } else if (isSentedRef.current) {
      setCountdown(120)
      isSentedRef.current = false
    }
    return () => clearTimeout(timer)
  }, [countdownResend])

  useEffect(() => {
    pinRef.current?.length && pinRef.current[0].focus()
  }, [])

  return (
    <form onSubmit={onLoginByCode}>
      <style jsx>{compStyle}</style>
      <style jsx>{pinFieldStyle}</style>
      {loading && <Loading />}
      <p className="mb-2">
        Please enter 6-digits code which are sent to your{' '}
        {prevStep === LoginStep.EMAIL ? 'email' : 'phone'}
      </p>
      <p className="is-link mb-8">
        {prevStep === LoginStep.EMAIL ? searchForm.email : searchForm.phone}
      </p>
      <div className="enter-code ml-auto mr-auto has-text-centered">
        <p className="mb-8">Enter the code here to continue</p>
        <div className="pincodes ml-5 mt-4 mb-2">
          <PinField
            ref={pinRef}
            length={6}
            type="text"
            inputMode="numeric"
            placeholder="&#9679;"
            className="pincode__input pincode__dot"
            autoComplete="one-time-code"
            onResolveKey={(code: string, ref?: HTMLInputElement) =>
              onTypeCode(code, ref || undefined)
            }
            onChange={(code: string) => setCode(code)}
            disabled={loading}
          />
        </div>

        <p className="help is-danger mb-7">{err}</p>
        {countdownResend < 1 ? (
          <div
            onClick={() => countdown <= 0 && resentCode()}
            className={`mt-3 countdown ml-auto mr-auto is-flex is-justify-content-center is-align-items-center ${
              countdown > 0 ? '' : 'is-resent'
            }`}
          >
            {countdown > 0 ? (
              <p>
                Expire in:
                <span className="ml-3" style={{ color: 'var(--warning-100)' }}>
                  {countdown}s
                </span>
              </p>
            ) : (
              <span>Resend code</span>
            )}
          </div>
        ) : (
          <p style={{ color: 'var(--success-50)' }}>Verification code was resent successfully</p>
        )}
      </div>
      <button className="mt-8 button is-primary mb-8" type="submit">
        Login
      </button>
      <p className="mt-8 is-link" onClick={() => nextStep()}>
        Try another ways
      </p>
      <p style={{ marginTop: '4rem' }}>
        Forgot your order information?
        <span className="ml-4 is-link" onClick={toForgotOrder}>
          Contact Us
        </span>
      </p>
      <HowToFindOrderModal open={isOpen} setOpen={setIsOpen} toForgotOrder={toForgotOrder} />
    </form>
  )
}

const compStyle = css`
  .enter-code {
    background: var(--white-100);
    box-shadow: 2px 4px 40px rgba(161, 178, 203, 0.25);
    border-radius: var(--round-16);
    padding: 2rem;
    max-width: 450px;

    font-size: 15px;
    line-height: 32px;

    p {
      color: var(--indigo-7);
      &.is-danger {
        color: var(--danger);
      }
    }
  }

  .countdown {
    border: 2px solid var(--indigo-1);
    box-sizing: border-box;
    border-radius: var(--round-4);
    width: 200px;
    height: 48px;

    &.is-resent {
      border: 2px solid var(--secondary-50);
      color: var(--secondary-100);
      cursor: pointer;

      span {
        font-weight: 500;
      }

      &:hover {
        background: var(--indigo-2);
        span {
          font-weight: 700;
        }
      }
    }
  }

  .button {
    width: 200px;
    height: 48px;
  }
`

const pinFieldStyle = css.global`
  .pincodes {
    .pincode__input {
      width: 45px;
      font-size: 24px;
      line-height: 40px;
      color: var(--gray-8);
      border: none;
      &:focus {
        outline: none;
      }
      &:not(:placeholder-shown) {
        text-align: center;
      }
      caret-color: var(--secondary-100);
    }
    .pincode__dot {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--indigo-4);
        padding-left: 5px;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--indigo-4);
        padding-left: 5px;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: var(--indigo-4);
        padding-left: 5px;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: var(--indigo-4);
        padding-left: 5px;
      }
    }
  }
`
