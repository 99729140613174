export enum LoginMethod {
  PHONE = 'phone',
  CODE = 'code',
  EMAIL = 'email'
}

export enum LoginStep {
  PHONE = 'BY_PHONE',
  EMAIL = 'BY_EMAIL',
  CODE = 'CODE',
  ORDER = 'ORDER'
}

export enum LoginApiMethod {
  EMAIL_ORDER = 'EMAIL_ORDER',
  EMAIL_CODE = 'EMAIL_CODE',
  PHONE_ORDER = 'PHONE_ORDER',
  PHONE_CODE = 'PHONE_CODE',
  GET_CODE_EMAIL = 'GET_CODE_EMAIL',
  GET_CODE_PHONE = 'GET_CODE_PHONE'
}
