import { Dispatch, SetStateAction } from 'react'
import css from 'styled-jsx/css'
import { shopHelpPath } from '@/helpers'

type compProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const ThanksLoginModal: React.FC<compProps> = ({ open, setOpen }) => {
  const shopHelpPhone = '(877) 778-8353'

  return (
    <>
      <style jsx>{modalStyle}</style>
      <div className={`modal thanks-modal ${open ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setOpen(false)} />
        <div className="modal-card">
          <header className="modal-card-head has-background-white">
            <p className="modal-card-title">
              Thank you for using
              <br className="is-hidden-desktop" />
              <span> this form.</span>
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setOpen((open) => !open)}
            />
          </header>
          <section className="modal-card-body">
            <p>
              We are very happy to let you know that our site will be fully upgraded and launch on
              <b> Jan 31, 2021.</b>
            </p>
            <p>
              In the meantime, please call our 24/7 customer service hotline toll-free (Canada and
              USA) at
            </p>
            <div className="phone-call mt-4 mb-7">
              <div className="tag mr-2" style={{ height: '3rem', width: '3rem' }}>
                <a href={`tel:+ 1 ${shopHelpPhone}`}>
                  {' '}
                  <figure className="image is20x20">
                    <img src={shopHelpPath('phone-blue.svg')} alt="Call ShopHelp" />
                  </figure>
                </a>
              </div>
              <div className="tag" style={{ height: '3rem', padding: '8px 58px 8px 29px' }}>
                <a href={`tel:+1 ${shopHelpPhone}`}>
                  <b>{shopHelpPhone}</b>
                </a>
              </div>
            </div>
            <p>Our agents will be proud to help you!</p>
          </section>
        </div>
      </div>
    </>
  )
}

const modalStyle = css`
  .thanks-modal {
    .modal-card {
      b {
        font-weight: 600;
      }

      &-title {
        font-size: 24px;
        line-height: 40px;
        font-weight: 600;
      }

      &-head {
        border: none;
        border-top-left-radius: var(--round-16);
        border-top-right-radius: var(--round-16);
        padding: 2.5rem 2.5rem 1.5rem;
      }

      &-body {
        padding: 0 2.5rem 2.75rem;
        border-bottom-left-radius: var(--round-16);
        border-bottom-right-radius: var(--round-16);

        p {
          width: 85%;
        }

        .phone-call {
          display: flex;
          align-items: center;

          .tag {
            border-radius: var(--round-8);
            background-color: var(--secondary-10);

            b {
              font-size: 16px;
              line-height: 32px;
              color: var(--secondary-100);
            }
          }
        }
      }
    }
  }
`
