import css from 'styled-jsx/css'
import { LoginStep } from '@components/login/enum'
import { SVGLoginOrder } from '@shared/SVG'
import HowToFindOrderModal from '@shared/Modal/HowToFindOrderModal'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'
import { parsePhone, shopHelpPath } from '@/helpers'
import { useAuthService } from '@/services/auth/auth.service'
import { useNotiContext } from '@shared/Notification'
import Loading from '@shared/Loading'
import { OrderContext } from '@/contexts'
import { useOrderService } from '@/services/order/order.service'
import { fireNextState, SubscribeStateKey } from '@helpers/stateSubscriber'
import getConfig from 'next/config'

interface LoginProps {
  currentStep: string
  nextStep: (step?: any) => void
  searchForm: { phone?: string; email?: string }
}

const contentByStep = {
  [LoginStep.EMAIL]: {
    title: 'Email',
    content: 'email',
    nextStep: 'Or continue by using phone number',
    placeholder: 'email'
  },
  [LoginStep.PHONE]: {
    title: 'Phone number',
    content: 'phone number',
    nextStep: 'Or continue by using email',
    placeholder: 'phone number'
  },
  [LoginStep.ORDER]: {
    title: 'Order number',
    content: 'Verify by an order you have bought',
    nextStep: 'Or Login by',
    placeholder: 'order number'
  }
} as any

const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const orderNumberRegex = /^#?[0-9]+-[0-9]+$/

function isValid(currentStep: string, value: string): string {
  let valid = false
  let type = ''
  switch (currentStep) {
    case LoginStep.PHONE:
      type = 'Phone number'
      valid = phoneRegex.test(value)
      break
    case LoginStep.EMAIL:
      type = 'Email'
      valid = emailRegex.test(value)
      break
    case LoginStep.ORDER:
      type = 'Order number'
      valid = orderNumberRegex.test(value)
      break
  }
  return !valid ? `${type} is not valid` : ''
}

export const FillLoginForm: React.FC<LoginProps> = ({ currentStep, nextStep, searchForm }) => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const { detailByInfo } = useOrderService(getConfig().publicRuntimeConfig.clientApi)
  const { notiDispatch } = useNotiContext()
  const { dispatchOrder } = useContext(OrderContext)

  useEffect(() => setInputValue(''), [currentStep])

  function toForgotOrder() {
    router.push({
      pathname: 'contact/forgot',
      query: { ...router.query, ref: 'login' }
    })
  }

  function onValueChange(value: string) {
    if (currentStep !== LoginStep.PHONE) {
      setInputValue(value)
    } else {
      setInputValue(parsePhone(value))
    }
  }

  function onConfirm() {
    const validMsg = isValid(currentStep, inputValue)
    if (validMsg) {
      setError(`${validMsg}`)
      return
    }
    setError('')
    currentStep === LoginStep.ORDER ? onLoginWithOrderNumber() : onSendCode()
  }

  async function onSendCode() {
    setIsLoading(true)
    const body: Types.LoginModel = {
      send_sms: currentStep === LoginStep.PHONE
    }
    if (currentStep === LoginStep.PHONE) {
      body.phone = inputValue
    } else {
      const domain = window.location.hostname
      body.email = inputValue
      body.redirect_url = `https://${domain}/list`
    }

    const result = await login(body, false).then(
      () => true,
      () => {
        notiDispatch({
          payload: {
            type: 'is-danger',
            title: 'Something went wrong',
            content: `We couldn't find your ${
              currentStep === LoginStep.EMAIL ? 'email' : 'phone number'
            }!`
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
        setError('true')
        return false
      }
    )
    setIsLoading(false)
    fireNextState(
      SubscribeStateKey.LOGIN_FORM,
      currentStep === LoginStep.PHONE ? { phone: inputValue } : { email: inputValue }
    )
    result && nextStep(LoginStep.CODE)
  }

  async function fetchOrder(body: Types.LoginModel) {
    try {
      await detailByInfo(body).then((res: Types.Order) =>
        dispatchOrder({ type: 'REPLACE', payload: res })
      )
    } catch (err) {}
  }

  async function onLoginWithOrderNumber() {
    const body: Types.LoginModel = { order: inputValue }
    searchForm.email ? (body.email = searchForm.email) : (body.phone = searchForm.phone)
    setIsLoading(true)
    const result = await login(body, false).then(
      async () => {
        await fetchOrder(body)
        return true
      },
      () => {
        notiDispatch({
          payload: {
            title: 'Invalid data',
            content: 'Order number not found!',
            type: 'is-danger',
            timeout: 10
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
        setError('true')
        return false
      }
    )
    setIsLoading(false)
    result && router.push('/detail')
  }

  return (
    <>
      <style jsx>{compStyle}</style>
      {isLoading && <Loading />}
      <div className="login-form ml-auto mr-auto has-text-centered">
        {currentStep === LoginStep.EMAIL && (
          <img alt={'login via email'} src={shopHelpPath('login-email.png')} />
        )}
        {currentStep === LoginStep.PHONE && (
          <img alt={'login via phone'} src={shopHelpPath('mobile_hand.svg')} />
        )}
        {currentStep === LoginStep.ORDER && <SVGLoginOrder />}
        <p className="login-form__title mt-6 mb-2">Contact us</p>
        {currentStep === LoginStep.ORDER && (
          <p className={'mb-4'}>
            Your {searchForm.phone ? 'phone number' : 'email'}:
            <span className="ml-2" style={{ color: 'var(--secondary-100)' }}>
              {searchForm.phone ? searchForm.phone : searchForm.email}
            </span>
          </p>
        )}
        <p className="login-form__content is-flex is-align-items-center is-justify-content-center">
          {currentStep === LoginStep.ORDER ? (
            <>
              {contentByStep[currentStep].content}
              <svg
                onClick={() => setIsOpen(true)}
                className="has-pointer ml-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4.25C7.72009 4.25 4.25 7.72134 4.25 12C4.25 16.2812 7.72009 19.75 12 19.75C16.2799 19.75 19.75 16.2812 19.75 12C19.75 7.72134 16.2799 4.25 12 4.25ZM12 18.25C8.54588 18.25 5.75 15.4553 5.75 12C5.75 8.54703 8.546 5.75 12 5.75C15.4528 5.75 18.25 8.54597 18.25 12C18.25 15.4541 15.4553 18.25 12 18.25ZM15.3514 10.275C15.3514 12.3704 13.0882 12.4026 13.0882 13.177V13.375C13.0882 13.5821 12.9203 13.75 12.7132 13.75H11.2867C11.0797 13.75 10.9117 13.5821 10.9117 13.375V13.1044C10.9117 11.9874 11.7586 11.5408 12.3986 11.182C12.9474 10.8744 13.2837 10.6651 13.2837 10.2577C13.2837 9.71875 12.5963 9.36103 12.0405 9.36103C11.3158 9.36103 10.9813 9.70406 10.511 10.2976C10.3842 10.4576 10.1529 10.4873 9.99022 10.3639L9.12072 9.70463C8.96113 9.58363 8.92537 9.35881 9.03809 9.19328C9.77644 8.10909 10.7169 7.5 12.1811 7.5C13.7145 7.5 15.3514 8.697 15.3514 10.275ZM13.3125 15.5C13.3125 16.2237 12.7237 16.8125 12 16.8125C11.2763 16.8125 10.6875 16.2237 10.6875 15.5C10.6875 14.7763 11.2763 14.1875 12 14.1875C12.7237 14.1875 13.3125 14.7763 13.3125 15.5Z"
                  fill="#0B92F1"
                />
              </svg>
            </>
          ) : (
            `Please fill out your ${contentByStep[currentStep].content} to continue`
          )}
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            onConfirm()
          }}
        >
          <div className="field mt-8 mb-4">
            <div className={`control ${currentStep !== LoginStep.EMAIL ? 'has-icons-left' : ''}`}>
              <input
                className={`input ${error ? 'is-danger' : ''}`}
                type={currentStep === LoginStep.EMAIL ? 'email' : 'text'}
                placeholder={`Enter your ${contentByStep[currentStep].placeholder}`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onValueChange(e.target.value)}
                value={inputValue}
              />
              {currentStep !== LoginStep.EMAIL && (
                <span className="is-left icon" style={{ height: '100%' }}>
                  {currentStep === LoginStep.PHONE && '+1'}
                  {currentStep === LoginStep.ORDER && '#'}
                </span>
              )}
            </div>
            {error && error !== 'true' && <p className="help is-danger">{error}</p>}
          </div>

          <button type="submit" className="btn-submit" disabled={!inputValue}>
            Continue
          </button>
        </form>
      </div>

      <div className="has-text-centered" style={{ marginTop: '4rem' }}>
        <p
          className="is-link mb-8"
          onClick={() => {
            nextStep()
            setError('')
          }}
        >
          {contentByStep[currentStep].nextStep}
          {currentStep === LoginStep.ORDER && ` ${searchForm.phone ? 'email' : 'phone number'}`}
        </p>
        <p className="mt-8" style={{ marginBottom: '4rem' }}>
          Forgot your order information?
          <span className="ml-4 is-link" onClick={toForgotOrder}>
            Contact Us
          </span>
        </p>
      </div>

      <HowToFindOrderModal open={isOpen} setOpen={setIsOpen} toForgotOrder={toForgotOrder} />
    </>
  )
}

const compStyle = css`
  .login-form {
    background: var(--white-100);
    box-shadow: 2px 4px 40px rgba(161, 178, 203, 0.25);
    border-radius: 4px;
    padding: 58px 16px 45px 16px;
    max-width: 343px;

    font-size: 15px;
    line-height: 32px;

    &__title {
      font-size: 18px;
      font-weight: 600;
    }

    &__content {
      color: #37383c;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
    }

    .icon.is-left {
      color: var(--indigo-7);
      &::after {
        content: '';
        position: absolute;
        border: 1px solid var(--indigo-3);
        height: 16px;
        right: 0;
      }
    }
    .control.has-icons-left .input {
      padding-left: 3rem;
    }
    .input {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--indigo-6);
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--indigo-6);
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: var(--indigo-6);
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: var(--indigo-6);
      }
    }
  }

  .btn-submit {
    padding: 14px 24px;
    background: #37383c;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    color: #ffffff;

    width: 100%;
  }
`
