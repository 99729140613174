import { isEmpty, sleep } from '@/helpers'
import { useRouter } from 'next/dist/client/router'
import { useNotiContext } from '@shared/Notification'
import { useAuthService } from '@/services/auth/auth.service'
import React, { useEffect, useState } from 'react'
import Loading from '@shared/Loading'
import Header from '@shared/Header'
import { SearchForm } from '@components/login/SearchForm'
import { VerifyEmail } from '@components/login/VerifyEmail'
import { VerifyPhone } from '@components/login/VerifyPhone'
import css from 'styled-jsx/css'
import getConfig from 'next/config'

type SearchStep = 'search' | 'email' | 'phone'

let currentLeft = 0

async function scrollToPage(
  curPageId: string,
  nextPageId: string,
  direction: 'left' | 'right' = 'right'
) {
  // root.style.setProperty('--primary', primary)
  direction === 'right' && (currentLeft += 100)
  const nextEl = document.getElementById(nextPageId)!
  const curEl = document.getElementById(curPageId)!
  nextEl.style.setProperty('display', 'block')
  nextEl.style.setProperty('left', `${currentLeft}%`)
  await sleep(150).then(() => {
    curEl.scrollIntoView()
    nextEl.scrollIntoView({ behavior: 'smooth' })
  })

  sleep(750).then(() => {
    curEl.style.setProperty('display', 'none')
    // Reset left property
    currentLeft = 0
  })
}

function resetScrollPage() {
  const searchForm = document.getElementById('searchForm')
  const verifyEmail = document.getElementById('verifyEmail')
  const verifyPhone = document.getElementById('verifyPhone')
  searchForm?.style.setProperty('display', 'block')
  sleep(750).then(() => {
    searchForm?.scrollIntoView({ behavior: 'smooth' })
    verifyEmail?.style.setProperty('display', 'none')
    verifyPhone?.style.setProperty('display', 'none')
  })
}

export const LoginForm: React.FC = () => {
  const router = useRouter()
  const { notiDispatch } = useNotiContext()
  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [order, setOrder] = useState<string>('')
  const [currentStep, setCurrentStep] = useState<SearchStep>('search')

  useEffect(() => {
    const { phone, email, order, ref } = router.query
    phone && onSentSms(phone + '')
    email && onSendEmail(email + '', ref + '')
    order && onSendVerifyWithOrderNumber(order + '', ref + '')
    if (isEmpty(router.query)) {
      resetScrollPage()
    }
  }, [router])

  async function onSentSms(phone: string) {
    if (currentStep === 'phone') {
      setPhone(phone + '')
      setIsLoading(true)
      await login({ phone }).then(
        () => {},
        () => notiTryAgain()
      )
      setIsLoading(false)
    }
  }

  async function onSendEmail(email: string, ref: string) {
    if (currentStep === 'email') {
      setEmail(email)
      setIsLoading(true)
      await login({
        email,
        send_sms: ref === 'phone',
        redirect_url: 'https://shophelp.dev/detail'
      }).then(
        () => {},
        () => notiTryAgain()
      )
      setIsLoading(false)
    }
  }

  async function onSendVerifyWithOrderNumber(order: string, ref: string) {
    if (currentStep === 'email' || currentStep === 'phone') {
      setOrder(order)
      const body: Types.LoginModel = { order, send: ref === 'email' ? 'email' : 'sms' }
      if (ref === 'email') {
        body.redirect_url = 'https://shophelp.dev/detail'
      }
      setIsLoading(true)
      await login(body).then(
        (res: any) => {
          res.phone && setPhone(res.phone)
          res.email && setEmail(res.email)
        },
        () => notiTryAgain()
      )
      setIsLoading(false)
    }
  }

  async function loginWithCode(code: string, type: 'email' | 'phone' | 'order') {
    const body: Types.LoginModel = {}
    body.code = code
    switch (type) {
      case 'email':
        body.email = email
        break
      case 'order':
        body.order = order
        break
      case 'phone':
        body.phone = phone
        break
    }
    setIsLoading(true)
    await login(body).then(
      () => {
        sleep(500).then(() => setIsLoading(false))
        router.push({
          pathname: '/detail',
          query: { order }
        })
      },
      () => {
        sleep(500).then(() => setIsLoading(false))
        notiError()
      }
    )
  }

  function notiError() {
    notiDispatch({
      payload: {
        title: 'Invalid data',
        content: "We couldn't find what you were looking for",
        type: 'is-danger',
        timeout: 2
      },
      type: 'REMOVE_ALL_AND_ADD'
    })
  }

  function notiTryAgain() {
    notiDispatch({
      payload: {
        type: 'is-danger',
        title: 'Error!',
        content: 'Something went wrong, please try again later'
      },
      type: 'REMOVE_ALL_AND_ADD'
    })
  }

  return (
    <>
      <style jsx global>
        {globalStyle}
      </style>
      {isLoading ? <Loading /> : null}
      <div className="login-page" id="searchForm">
        <Header />
        <div className="login-page__content">
          <SearchForm
            toVerifyEmail={() => {
              setCurrentStep('email')
              scrollToPage('searchForm', 'verifyEmail').then()
            }}
            toVerifyPhone={() => {
              setCurrentStep('phone')
              scrollToPage('searchForm', 'verifyPhone').then()
            }}
          />
        </div>
      </div>
      <div className="login-page" id="verifyEmail">
        <Header />
        <div className="login-page__content">
          <VerifyEmail
            order={order}
            email={email}
            isLoading={isLoading}
            onSendCode={(code: string, type: 'order' | 'email' | 'phone') =>
              loginWithCode(code, type)
            }
            toSearchForm={() => {
              setCurrentStep('search')
              scrollToPage('verifyEmail', 'searchForm', 'left').then()
            }}
          />
        </div>
      </div>
      <div className="login-page" id="verifyPhone">
        <Header />
        <div className="login-page__content">
          <VerifyPhone
            order={order}
            phone={phone}
            isLoading={isLoading}
            onSendCode={(code: string, type: 'order' | 'email' | 'phone') =>
              loginWithCode(code, type)
            }
            toSearchForm={() => {
              setCurrentStep('search')
              scrollToPage('verifyPhone', 'searchForm', 'left').then()
            }}
          />
        </div>
      </div>
    </>
  )
}

const globalStyle = css.global`
  #verifyEmail {
    display: none;
  }
  #verifyPhone {
    display: none;
  }

  .login-page {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;

    &__content {
      padding-top: 10%;

      @media screen and (max-width: 768px) {
        padding-top: 15%;
      }
    }
  }
`
