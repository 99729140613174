import { PinField } from '../shared/PinField'
import css from 'styled-jsx/css'
import { useAuthService } from '@/services/auth/auth.service'
import { useNotiContext } from '../shared/Notification'
import getConfig from 'next/config'

interface IVerifyPhone {
  toSearchForm: () => void
  phone: string
  order: string
  onSendCode: (code: string, type: 'order' | 'phone') => any
  isLoading: boolean
}

export const VerifyPhone: React.FC<IVerifyPhone> = ({
  toSearchForm,
  phone,
  onSendCode,
  isLoading,
  order
}) => {
  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const { notiDispatch } = useNotiContext()

  function onVerifyPhone(code: string) {
    onSendCode(code, phone && phone?.indexOf('*') < 0 ? 'phone' : 'order')
  }

  async function resentCode() {
    const body: Types.LoginModel = phone.indexOf('*') < 0 ? { phone } : { order, send: 'sms' }
    await login(body).then(
      () => {},
      () =>
        notiDispatch({
          payload: {
            title: 'Error!',
            type: 'is-danger',
            content: 'Something went wrong, please try again later'
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
    )
  }

  function onTypeCode(code: string, ref?: HTMLInputElement) {
    if (ref && code) {
      ref.value = code.toUpperCase()
    }
  }

  function hidePhone(phoneNumber: string) {
    const prePhone = phoneNumber.substring(0, 5)
    const lengthMidPhone = phoneNumber.substring(5, phoneNumber.length - 3).length
    let midPhone = ''
    for (let i = 0; i < lengthMidPhone; i++) {
      midPhone = midPhone + '*'
    }
    const sufPhone = phoneNumber.substring(phoneNumber.length - 3, phoneNumber.length)
    return prePhone + midPhone + sufPhone
  }

  let transformPhone = ''
  if (phone && phone.indexOf('*') < 0) {
    transformPhone = hidePhone(phone)
  } else {
    transformPhone = phone || ''
  }

  return (
    <div className="verify-phone">
      <style jsx>{globalStyle}</style>
      <div className="container ">
        <h3 className="verify-phone__title">Verification code has been sent to your phone</h3>
        <div className="verify-phone__phone">{transformPhone}</div>

        <div className="columns verify-phone__box">
          <div className="column is-12 verify-phone__item">
            <p className="mb-6">Enter the code here to continue.</p>
            <div className="verify-phone__pincode">
              <PinField
                length={6}
                placeholder="&#9679;"
                className="pincode__input pincode__dot"
                onComplete={(code: string) => onVerifyPhone(code)}
                onResolveKey={(code: string, ref?: HTMLInputElement) =>
                  onTypeCode(code, ref || undefined)
                }
                disabled={isLoading}
              />
            </div>
          </div>
        </div>

        <p className="verify-phone__resent">
          Have not received the code?&ensp;
          <span className="is-link" onClick={resentCode}>
            Resent code
          </span>
          &ensp; or&ensp;
          <span className="is-link" onClick={() => toSearchForm()}>
            Try another way
          </span>
        </p>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .verify-phone {
    color: var(--gray-8);
    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }
    &__phone {
      font-size: 15px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      color: var(--secondary-100);
    }
    &__box {
      max-width: 736px;
      margin: 0 auto;
      border: 1px solid var(--indigo-3);
      border-radius: 16px;
      margin-top: 64px;
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: auto;
      padding-top: 75px;
      padding-bottom: 75px;

      p {
        line-height: 32px;
      }
    }
    &__resent {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
      @media screen and (max-width: 768px) {
        margin-bottom: 64px;
      }
    }
    &__pincode {
      .pincode__input {
        width: 45px;
        font-size: 24px;
        line-height: 40px;
        color: var(--gray-8);
        border: none;
        &:focus {
          outline: none;
        }
        &:not(:placeholder-shown) {
          text-align: center;
        }
        caret-color: var(--indigo-7);
      }
      .pincode__dot {
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--indigo-4);
          padding-left: 5px;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--indigo-4);
          padding-left: 5px;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          color: var(--indigo-4);
          padding-left: 5px;
        }
        :-moz-placeholder {
          /* Firefox 18- */
          color: var(--indigo-4);
          padding-left: 5px;
        }
      }
    }
  }
`
