import { useEffect, useRef, useState } from 'react'

import Image from 'next/image'
import css from 'styled-jsx/css'
import { shopHelpPath, sleep } from '@/helpers'
import { useAuthService } from '@/services/auth/auth.service'
import { useKeyPress } from '@/hooks'
import { useNotiContext } from '@shared/Notification'
import { useRouter } from 'next/dist/client/router'
import { getImageDomainLink } from '@helpers/domain'
import getConfig from 'next/config'

interface ISearchForm {
  toVerifyEmail: () => void
  toVerifyPhone: () => void
}

type FieldType = 'order' | 'email' | 'phone' | ''
type FieldConfig = {
  type: FieldType
  valid: boolean | undefined
}

const firstFieldConfig: FieldConfig = {
  type: '',
  valid: undefined
}
const secondFieldConfig: FieldConfig = {
  type: '',
  valid: undefined
}
let secondFieldPlaceholder = ''

const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const orderNumberRegex = /^#?[0-9]+-[0-9]+$/

export const SearchForm: React.FC<ISearchForm> = ({ toVerifyEmail, toVerifyPhone }) => {
  const { notiDispatch } = useNotiContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [firstField, setFirstField] = useState<string>('')
  const [isValidFirstField, setIsValidFirstField] = useState<boolean>(false)
  const [secondField, setSecondField] = useState<string>('')

  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const router = useRouter()

  const firstFieldRef = useRef<HTMLInputElement>(null)
  const firstFieldPress = useKeyPress('Enter', firstFieldRef.current || undefined)
  useEffect(() => {
    if (firstFieldPress && firstFieldRef.current) {
      onFind(true)
    }
  }, [firstFieldPress])

  const secondFieldRef = useRef<HTMLInputElement>(null)
  const secondFieldPress = useKeyPress('Enter', secondFieldRef.current || undefined)
  useEffect(() => {
    if (secondFieldPress && secondFieldRef.current) {
      onTrackItNow()
    }
  }, [secondFieldPress])

  const onFind = (notifyEmpty?: boolean) => {
    if (!firstField) {
      notifyEmpty && notiError('Please type in what you want to find.')
      // firstFieldRef.current?.focus()
      return
    }
    setIsLoading(true)
    sleep(500).then(() => setIsLoading(false))
    if (!checkFirstFieldType()) {
      setIsValidFirstField(false)
      return
    }
    if (!isValidFirstField) {
      sleep(500).then(() => {
        setIsValidFirstField(true)
        secondFieldRef.current?.focus()
      })
    }
  }

  async function onTrackItNow() {
    setIsLoading(true)
    if (!firstField) {
      notiError('Please type in all required fields')
      firstFieldConfig.valid = false
      sleep(500).then(() => setIsLoading(false))
      return
    }
    if (!secondField) {
      notiError('Please type in all required fields')
      secondFieldConfig.valid = false
      sleep(500).then(() => setIsLoading(false))
      return
    }
    if (!checkFirstFieldType()) {
      sleep(500).then(() => setIsLoading(false))
      return
    }
    if (!validateSecondField()) {
      sleep(500).then(() => setIsLoading(false))
      return
    }
    const loginForm: Types.LoginModel = {
      [firstFieldConfig.type]: firstField.trim(),
      [secondFieldConfig.type]: secondField.trim()
    }
    await login(loginForm).then(
      () => {
        setIsLoading(false)
        router.push({
          pathname: '/detail',
          query: {
            order: getFieldTypeValue('order')
          }
        })
      },
      () => {
        notiError()
        firstFieldConfig.valid = false
        secondFieldConfig.valid = false
        setIsLoading(false)
      }
    )
  }

  function checkFirstFieldType(): boolean {
    if (phoneRegex.test(firstField)) {
      secondFieldPlaceholder = 'Enter your order number'
      firstFieldConfig.type = 'phone'
      secondFieldConfig.type = 'order'
    } else if (emailRegex.test(firstField)) {
      secondFieldPlaceholder = 'Enter your order number'
      firstFieldConfig.type = 'email'
      secondFieldConfig.type = 'order'
    } else if (orderNumberRegex.test(firstField)) {
      secondFieldPlaceholder = 'Enter your email or phone number...'
      firstFieldConfig.type = 'order'
      secondFieldConfig.type = ''
    } else {
      notiError()
      firstFieldConfig.type = ''
    }
    firstFieldConfig.valid = !!firstFieldConfig.type
    return firstFieldConfig.valid
  }

  function validateSecondField(): boolean {
    if (secondFieldConfig.type === 'order' && !orderNumberRegex.test(secondField)) {
      notiError()
    } else if (secondFieldConfig.type !== 'order') {
      if (phoneRegex.test(secondField)) {
        secondFieldConfig.type = 'phone'
      } else if (emailRegex.test(secondField)) {
        secondFieldConfig.type = 'email'
      } else {
        notiError()
        firstFieldConfig.type = ''
      }
    }
    secondFieldConfig.valid = !!secondFieldConfig.type
    return secondFieldConfig.valid
  }

  function getFieldTypeValue(type: FieldType) {
    const value =
      firstFieldConfig.type === type
        ? firstField
        : secondFieldConfig.type === type
        ? secondField
        : ''
    return value
  }

  function onToEmail() {
    const email = getFieldTypeValue('email')
    if (email) {
      router.push({
        query: { email }
      })
      toVerifyEmail()
      return
    }
    const order = getFieldTypeValue('order')
    if (order) {
      router.push({
        query: {
          order,
          ref: 'email'
        }
      })
      toVerifyEmail()
      return
    }
    notiError()
  }

  function onToSMS() {
    const phone = getFieldTypeValue('phone')
    if (phone) {
      router.push({
        query: { phone }
      })
      toVerifyPhone()
      return
    }
    const order = getFieldTypeValue('order')
    if (order) {
      router.push({
        query: {
          order,
          ref: 'phone'
        }
      })
      toVerifyPhone()
      return
    }
    const email = getFieldTypeValue('email')
    if (email) {
      router.push({
        query: {
          email,
          ref: 'phone'
        }
      })
      toVerifyPhone()
    }
    notiError()
  }

  function notiError(msg?: string) {
    notiDispatch({
      payload: {
        title: 'Invalid data',
        content: msg || "We couldn't find what you were looking for",
        type: 'is-danger',
        timeout: 10
      },
      type: 'REMOVE_ALL_AND_ADD'
    })
  }

  return (
    <div className="search-form">
      <style jsx>{globalStyle}</style>
      <p className="search-form__title">
        {isValidFirstField ? 'One more step' : 'Find your order'}
      </p>
      <div className="search-form__fields">
        <div
          className={`control${isValidFirstField ? ' has-icons-right' : ''}${
            isLoading ? ' is-loading' : ''
          }`}
        >
          <input
            ref={firstFieldRef}
            className={`input${firstFieldConfig.valid === false ? ' is-danger' : ''}`}
            name="firstField"
            type="text"
            placeholder="Enter your email, order number, phone ..."
            readOnly={isLoading ? true : false}
            value={firstField}
            onInput={(e) => setFirstField((e.target as HTMLInputElement).value)}
            onBlur={() => onFind()}
          />
          {isValidFirstField ? (
            <span className="icon is-large is-right">
              <figure className="image is24x24">
                <img
                  alt="step status"
                  src={shopHelpPath(
                    `${firstFieldConfig.valid === false ? 'canceled' : 'checked'}.svg`
                  )}
                />
              </figure>
            </span>
          ) : null}
        </div>
        {isValidFirstField ? (
          <>
            <div className="break-flex"></div>
            <div className="control mt-6">
              <input
                ref={secondFieldRef}
                className={`input${secondFieldConfig.valid === false ? ' is-danger' : ''}`}
                name="firstField"
                type="text"
                placeholder={secondFieldPlaceholder}
                readOnly={isLoading ? true : false}
                value={secondField}
                onInput={(e) => setSecondField((e.target as HTMLInputElement).value)}
                onBlur={() => {
                  if (secondField) {
                    validateSecondField()
                  }
                }}
              />
            </div>
          </>
        ) : null}
      </div>
      <button
        className={`button is-primary${isLoading ? ' is-loading' : ''}`}
        onClick={() => (isValidFirstField ? onTrackItNow() : onFind(true))}
      >
        {isValidFirstField ? 'Track it now' : 'Find'}
      </button>
      {isValidFirstField ? (
        <div className="search-form__other">
          <p className="other-title">Or try an other way</p>
          <div className="is-link" onClick={onToEmail}>
            Get link from your email
            <span className="icon is-right">
              <Image
                width={16}
                height={16}
                src={getImageDomainLink(
                  `${getConfig().publicRuntimeConfig.cdn}/img/arrow_right_blue.svg`
                )}
              />
            </span>
          </div>
          <div className="is-link" onClick={onToSMS}>
            Active code from your phone
            <span className="icon is-right">
              <Image width={16} height={16} src={shopHelpPath('arrow_right_blue.svg')} />
            </span>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const globalStyle = css`
  .search-form {
    text-align: center;

    &__title {
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
    }

    &__fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 32px;

      .control {
        &.has-icons-right .icon {
          top: 4px;
        }
        @media screen and (max-width: 768px) {
          max-width: 90%;
        }
      }

      input {
        width: 450px;
        height: 48px;
      }
    }
    button {
      margin-top: 48px;
      height: 48px;
      min-width: 200px;
      @media screen and (max-width: 768px) {
        width: 90%;
        margin-top: 32px;
      }
    }
    &__other {
      max-width: 450px;
      text-align: left;
      margin: 0 auto;
      margin-top: 8%;

      .other-title {
        color: var(--indigo-7);
        line-height: 32px;
        cursor: default;
        margin-bottom: 32px;
      }
      .is-link {
        margin-bottom: 32px;
        color: var(--blue-2);
        cursor: pointer;
      }
      .icon {
        margin-left: 12px;
        vertical-align: middle;
      }
      @media screen and (max-width: 768px) {
        margin-top: 15%;
        width: 90%;
        .other-title,
        .is-link {
          margin-bottom: 16px;
        }
      }
    }

    .control.is-loading::after {
      margin-top: 6px;
    }
  }
`
