import Image from 'next/image'
import { PinField } from '../shared/PinField'
import css from 'styled-jsx/css'
import { useAuthService } from '@/services/auth/auth.service'
import { useNotiContext } from '@shared/Notification'
import getConfig from 'next/config'
import { shopHelpPath } from '@/helpers'

interface IVerifyEmail {
  toSearchForm: () => void
  email: string
  order: string
  onSendCode: (code: string, type: 'order' | 'email') => any
  isLoading: boolean
}

export const VerifyEmail: React.FC<IVerifyEmail> = ({
  toSearchForm,
  email,
  onSendCode,
  isLoading,
  order
}) => {
  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const { notiDispatch } = useNotiContext()

  function onCompleteCode(code: string) {
    onSendCode(code, email && email?.indexOf('*') < 0 ? 'email' : 'order')
  }

  function hideEmail(email: string) {
    const preEmail = email.substring(0, 3)
    const lengthMidEmail1 = email.indexOf('@')
    let midEmail1 = ''
    for (let i = 0; i < lengthMidEmail1 - 3; i++) {
      midEmail1 = midEmail1 + '*'
    }
    const lengthMidEmail2 = email.indexOf('.', lengthMidEmail1)
    let midEmail2 = ''
    for (let i = 0; i < lengthMidEmail2 - lengthMidEmail1 - 1; i++) {
      midEmail2 = midEmail2 + '*'
    }
    const sufEmail = email.substring(lengthMidEmail2, email.length)
    return preEmail + midEmail1 + '@' + midEmail2 + sufEmail
  }

  // TODO: Check url with current env
  async function resentEmail() {
    const body: Types.LoginModel = { redirect_url: 'https://shophelp.dev/detail' }
    if (email.indexOf('*') < 0) {
      body.email = email
    } else {
      body.order = order
      body.send = 'email'
    }
    await login({ email, redirect_url: 'https://shophelp.dev/detail' }).then(
      () => {},
      () =>
        notiDispatch({
          payload: {
            title: 'Error!',
            type: 'is-danger',
            content: 'Something went wrong, please try again later'
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
    )
  }

  function onTypeCode(code: string, ref?: HTMLInputElement) {
    if (ref && code) {
      ref.value = code.toUpperCase()
    }
  }

  let transfromEmail = ''
  if (email && email.indexOf('*') < 0) {
    transfromEmail = hideEmail(email)
  } else {
    transfromEmail = email || ''
  }

  return (
    <div className="verify-email">
      <style jsx>{globalStyle}</style>
      <div className="container ">
        <h3 className="verify-email__title">Access link has been sent to your Email,</h3>
        <div className="verify-email__email">{transfromEmail}</div>

        <div className="columns verify-email__box">
          <div className="column verify-email__pin">
            <p className="mb-6">Enter the code here to continue.</p>
            <div className="verify-email__pincode">
              <PinField
                length={6}
                placeholder="&#9679;"
                className="pincode__input pincode__dot"
                onComplete={(code: string) => onCompleteCode(code)}
                onResolveKey={(code: string, ref?: HTMLInputElement) =>
                  onTypeCode(code, ref || undefined)
                }
                disabled={isLoading}
              />
            </div>
          </div>
        </div>

        <div className="columns verify-email__box">
          <div className="column verify-email__item">
            <Image
              width={180}
              height={180}
              src={shopHelpPath('tut_email_1.png')}
              alt="Tutorial icon"
              quality="100"
            />
            <p>Please check your inbox to find Access link</p>
          </div>
          <div className="column verify-email__item">
            <Image
              width={180}
              height={180}
              src={shopHelpPath(`tut_email_2.png`)}
              alt="Tutorial icon"
              quality="100"
            />
            <p>Please check your spam/junk folder too</p>
          </div>
        </div>

        <p className="verify-email__resent">
          Have not received the link?&ensp;
          <span className="is-link" onClick={resentEmail}>
            Resent link
          </span>
          &ensp; or&ensp;
          <span className="is-link" onClick={() => toSearchForm()}>
            Try another way
          </span>
        </p>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .is-link {
    cursor: pointer;
    color: var(--secondary-100);
  }
  .verify-email {
    color: var(--gray-8);
    margin-top: -6%;
    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }
    &__email {
      font-size: 15px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      color: var(--secondary-100);
      margin-top: 8px;
      margin-bottom: 32px;
    }
    &__box {
      max-width: 736px;
      margin: 0 auto;
      border: 1px solid var(--indigo-3);
      border-radius: 16px;
      margin-top: 16px;
    }
    &__pin {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: auto;
      padding: 32px;
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 210px;
      margin: auto;
      padding-top: 64px;
      padding-bottom: 64px;

      p {
        margin-top: 32px;
      }
    }
    &__resent {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
      @media screen and (max-width: 768px) {
        margin-bottom: 64px;
      }
    }
    &__pincode {
      .pincode__input {
        width: 45px;
        font-size: 24px;
        line-height: 40px;
        color: var(--gray-8);
        border: none;
        &:focus {
          outline: none;
        }
        &:not(:placeholder-shown) {
          text-align: center;
        }
        caret-color: var(--indigo-7);
      }
      .pincode__dot {
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--indigo-4);
          padding-left: 5px;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--indigo-4);
          padding-left: 5px;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          color: var(--indigo-4);
          padding-left: 5px;
        }
        :-moz-placeholder {
          /* Firefox 18- */
          color: var(--indigo-4);
          padding-left: 5px;
        }
      }
    }
  }
`
